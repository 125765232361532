<template>
  <b-overlay ref="overlay" :show="downloading" variant="light">
    <slot :downloadFile="downloadFile">
      <b-button variant="outline-secondary" @click="downloadFile">Download</b-button>
    </slot>
  </b-overlay>
</template>
<script>
export default {
  props: ['retrieveData', 'filename'],
  data() {
    return {
      downloading: false
    };
  },
  methods: {
    async downloadFile() {
      document.activeElement.blur();
      this.downloading = true;

      try {
        const data = await this.retrieveData();

        const fileURL = window.URL.createObjectURL(new Blob([data]));
        const fileLink = document.createElement('a');

        let filename = this.filename;

        if (typeof filename === 'function') {
          filename = this.filename();
        }

        fileLink.href = fileURL;
        fileLink.setAttribute('download', filename);
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (error) {
        this.$emit('error', error);
      }

      this.downloading = false;
    }
  }
};
</script>
