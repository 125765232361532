<template>
  <GenericModal :title="title" size="md" :config="modalConfig" @modal-closed="modalClosed" :errorMessage="errorMessage">
    <template #openButton="{ openModal }">
      <div>
        <RbButton variant="secondary-outline" iconVariant="faArrowUpFromLine" @click.native="openModal">
          Import
        </RbButton>
      </div>
    </template>
    <template>
      <div class="flex flex-column py-4 gap-4">
        <Alert v-if="errorMessage" variant="red" icon="exclamation">
          <strong>Failed to upload file</strong>
          <p>{{ errorMessage }}</p>
        </Alert>

        <div class="flex flex-wrap flex-row align-items-center" :class="{ 'shrink-input': isUploading }">
          <RbLoadingSpinner size="sm" v-if="isUploading" class="mr-2" />
          <b-form-file
            name="input-customers-upload"
            v-model="customersFile"
            accept=".csv"
            v-validate="{}"
            @input="uploadFile"
            no-drop
          ></b-form-file>
        </div>
        <p>
          Upload a CSV that includes your customers to import here and click the Dry Run button below. We'll check to
          see if there are any formatting errors in it and display them back to you. Unsure what format to follow?
          Download this
          <a href="https://rafflebox-docs.s3.ca-central-1.amazonaws.com/customer-import.csv" target="_blank">
            sample CSV
          </a>
          to help format your data.
        </p>
        <p>
          <strong>Please note</strong> that we will only show the first error in your CSV. If you receive an error
          during the dry run check to see if there are others before re-uploading to reduce the number of times you need
          to upload.
        </p>
        <div class="border rounded-lg border-gray-300 text-sm h-40 font-mono p-3 overflow-y-auto">
          <p v-if="validationResponse.length < 1" class="text-sm text-gray-600 font-mono p-2">
            Any errors in your CSV will appear here during the Dry Run.
          </p>
          <div v-else>
            <div v-for="error in validationResponse" :key="error.error" class="mb-4">
              <div class="flex justify-items-stretch">
                <div class="flex flex-col align-items-start" v-if="!error.isSuccessful">
                  <p class="mb-1 text-red-600" v-if="error.line">Error: Line {{ error.line }}</p>
                  <p class="mb-1 text-red-600">{{ error.line ? error.error : error.message }}</p>
                </div>
                <div class="flex flex-col align-items-start" v-else>
                  <p class="mb-1 text-green-600">{{ error.error }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-content-end">
          <RbButton
            variant="success-outline"
            size="small"
            :disabled="disableDryRunButton"
            :loading="dryRunLoading"
            class="mr-2 whitespace-nowrap"
            @click.native="importFile(true)"
          >
            Dry Run
          </RbButton>
          <RbButton
            variant="success"
            size="small"
            :disabled="disableImportButton"
            :loading="importLoading"
            class="whitespace-nowrap"
            @click.native="importFile(false)"
          >
            Import
          </RbButton>
        </div>
      </div>
    </template>
    <template slot="footer" slot-scope="{ closeModal }">
      <div class="flex py-1">
        <RbButton variant="secondary" @click.native="closeModalHandler(closeModal)" class="mr-2"> Cancel </RbButton>
      </div>
    </template>
  </GenericModal>
</template>

<script>
import GenericModal from '@/components/modals/GenericModal.vue';
import Alert from '@/components/ui/Alert.vue';

import CustomerServiceV2 from '@/lib/customer-service-v2';
import FileUploadServiceV2 from '@/lib/file-upload-service-v2';

export default {
  components: {
    Alert,
    GenericModal
  },
  data() {
    return {
      title: 'Import Customers',
      modalConfig: {
        showHeaderClose: true,
        showCloseModalButton: false
      },
      customersFile: null,
      s3Url: null,
      disabled: false,
      isUploading: false,
      isImporting: false,
      dryRunSuccessful: false,
      importSuccessful: false,
      validationResponse: [],
      errorMessage: null
    };
  },
  computed: {
    dryRunLoading() {
      return this.isImporting && !this.dryRunSuccessful;
    },
    importLoading() {
      return this.isImporting && this.importSuccessful;
    },
    disableDryRunButton() {
      return !this.customersFile && !this.isUploading;
    },
    disableImportButton() {
      // Enable import button if dry run was successful and disable it after successful import to avoid duplication of customers
      return !(this.dryRunSuccessful && !this.importSuccessful) || this.isImporting;
    },
    disableCancelButton() {
      return this.isImporting;
    }
  },
  methods: {
    formatS3Url(url) {
      return url.split('?').shift();
    },
    async createUploadUrl() {
      try {
        this.s3Url = await FileUploadServiceV2.getUploadUrl();

        return this.s3Url;
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
    },
    async uploadFile() {
      // Reset previous details
      this.errorMessage = null;
      this.s3Url = null;
      this.dryRunSuccessful = false;
      this.importSuccessful = false;
      this.validationResponse = [];

      // Safeguard against empty uploads
      if (!this.customersFile) {
        return;
      }

      try {
        this.isUploading = true;

        // Safeguard against non-CSV uploads
        if (this.customersFile.type === 'text/csv' || this.customersFile.type === 'application/vnd.ms-excel') {
          const uploadUrl = await this.createUploadUrl();
          await FileUploadServiceV2.uploadToS3(uploadUrl, this.customersFile);
        } else {
          this.errorMessage = `
            ${this.customersFile.type.split('/').pop().toUpperCase()}
            is not a supported file type. Please upload a CSV file.
          `;
        }
      } catch (error) {
        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      } finally {
        this.isUploading = false;
      }
    },
    async importFile(dryRun) {
      this.validationResponse = [];
      try {
        this.isImporting = true;
        const orgId = this.$store.state.organization.id;

        const params = {
          url: this.formatS3Url(this.s3Url),
          orgId,
          dryRun
        };

        const response = await CustomerServiceV2.importCustomers(params);

        // Set success responses for dry run and import
        if (dryRun) {
          this.validationResponse.push({
            error: 'Dry run successful, you can now import your customers.',
            line: 0,
            customer: {},
            isSuccessful: true
          });
          this.validationResponse.push({
            error: `Total customers: ${response.customers}`,
            line: 1,
            customer: {},
            isSuccessful: true
          });
          this.dryRunSuccessful = true;
        } else {
          this.validationResponse.push({ error: 'Import processing.', line: 0, customer: {}, isSuccessful: true });
          this.importSuccessful = true;
        }
      } catch (error) {
        const errors = error.response.data.errors;

        // If the error is an array of objects with line and customer properties, it's a validation error
        if (errors.length > 0 && Object.hasOwn(errors[0], 'dryRunError')) {
          this.validationResponse = errors;
        } else {
          this.errorMessage = errors[0].error;
        }
      } finally {
        this.isImporting = false;
      }
    },
    resetModal() {
      this.errorMessage = null;
      this.customersFile = null;
      this.disabled = false;
      this.isUploading = false;
      this.isImporting = false;
      this.dryRunSuccessful = false;
      this.importSuccessful = false;

      this.s3Url = null;
      this.validationResponse = [];
    },
    closeModalHandler(closeModal) {
      closeModal();
    },
    modalClosed() {
      this.resetModal();
      this.$emit('refreshTable');
    }
  }
};
</script>

<style lang="scss">
.shrink-input {
  .custom-file {
    display: inline-block;
    max-width: calc(100% - 3rem);
  }
}
</style>
